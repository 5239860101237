import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout className="sidebar-right">
    <SEO title="About EZAA" />
    <article>
      <h1>About EZAA</h1>
      <section>
        <p>The purpose of the EZAA is to keep the alumni of the Epsilon Zeta chapter of Alpha Phi Omega informed about the chapter's goings on, events, and ways to stay active by participating in service events.</p>
        <p>It is also a great way for EZ alumni to keep in touch. Every brother of Alpha Phi Omega who has left RPI is automatically a member, as are active brothers who are seniors or graduate students.</p>
      </section>
      <section>
        <h3>Donate to EZAA</h3>
        <p>Please consider donating to the Epsilon Zeta Alumni Association. Your support helps us continue to grow and provide services to members. Additionally, your support allows us to provide life memberships to chapter DSK recipients, as well as fund gifts to our alma matter, RPI.</p>
        <p>The EZAA is 501(c)(3) so your donation is tax deductible. If we forget to send you a thank-you letter and receipt for your records, please send an e-mail to the current treasurer indicating the date and dollar amount of your donation.</p>
        <p>To donate, send a check or money order (made out to Epsilon Zeta Alumni Association) to:<br />
          Epsilon Zeta Alumni Association<br />
          c/o Alpha Phi Omega Epsilon Zeta Chapter<br />
          RPI Union Room 3420<br />
          Troy, NY 12180<br />
        </p>
      </section>
    </article>
    <aside className="sidebar sidebar-right">
      <section className="officers">
        <h3 className="block-title">Alumni Association Officers</h3>
        <ul>
          <li><strong>President:</strong>&nbsp;<span>Richard Vehlow, 1991</span></li>
          <li><strong>Vice President:</strong>&nbsp;<span>Kenneth Bienstock, 1981</span></li>
          <li><strong>Secretary-Treasurer:</strong>&nbsp;<span>David Nagy, 1985</span></li>
          <li><strong>Extension Chair:</strong>&nbsp;<span>David Roggenkamp, 1985</span></li>
          <li><strong>Member-at-Large:</strong>&nbsp;<span>Seth Judson, 2019</span></li>
        </ul>
      </section>
    </aside>
  </Layout>
)

export default AboutPage;
